import { css } from '@emotion/react';
import { BreakPoint, greaterThan, orBelow, touchDevice } from '@ridi-web/design-system/Styles/cjs';

export const ITEM_LEFT_MARGIN_MOBILE = 16;
const ITEM_RIGHT_MARGIN_MOBILE = 16;
export const ITEM_LEFT_MARGIN_DESKTOP = 30;
const ITEM_RIGHT_MARGIN_DESKTOP = 30;
const ITEM_GAP = 6;
const NEXT_ITEM_OFFSET = 34;
export const ITEM_WIDTH_OFFSET_MOBILE = ITEM_LEFT_MARGIN_MOBILE + ITEM_GAP + NEXT_ITEM_OFFSET;
export const ITEM_WIDTH_OFFSET_DESKTOP = ITEM_LEFT_MARGIN_DESKTOP + ITEM_GAP + NEXT_ITEM_OFFSET;

export const titleLogoStyle = css`
  width: 95px;
  margin-left: 7px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 76px;
      margin-left: 5px;
    `,
  )}
`;

export const sectionArrowContainerStyle = css`
  width: calc(100% + 40px);
  margin: 0 -20px;
`;

export const singleItemWrapperStyle = css`
  margin: 0px auto;
`;

export const sectionContainerStyle = touchDevice(css`
  padding: 0 ${ITEM_LEFT_MARGIN_MOBILE * 2}px 0 0;

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 ${ITEM_LEFT_MARGIN_DESKTOP * 2}px 0 0;
    `,
  )}
`);

export const onePickItemStyle = css`
  width: calc(100vw - ${ITEM_WIDTH_OFFSET_MOBILE}px);
  height: 406px;
  padding-right: ${ITEM_GAP}px;

  &:only-child {
    width: calc(100vw - ${ITEM_LEFT_MARGIN_MOBILE + ITEM_RIGHT_MARGIN_MOBILE}px);
  }

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      max-width: 1110px;
      width: calc(100vw - ${ITEM_LEFT_MARGIN_DESKTOP + ITEM_RIGHT_MARGIN_DESKTOP}px);
      padding-right: 0px;

      &:first-of-type {
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }
    `,
  )};

  ${touchDevice(css`
    &:first-of-type {
      margin-left: ${ITEM_LEFT_MARGIN_MOBILE}px;
    }

    &:last-of-type {
      margin-right: ${ITEM_RIGHT_MARGIN_MOBILE}px;
      padding-right: 0px;
    }

    ${greaterThan(
      BreakPoint.DesktopSmall,
      css`
        padding-right: ${ITEM_GAP}px;

        &:first-of-type {
          margin-left: ${ITEM_LEFT_MARGIN_DESKTOP}px;
        }

        &:last-of-type {
          margin-right: ${ITEM_RIGHT_MARGIN_DESKTOP}px;
          padding-right: 0px;
        }
      `,
    )};
  `)}
`;

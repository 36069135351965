import { css } from '@emotion/react';
import { RIDITheme } from '@ridi-web/design-system/Styles/cjs';

import { ITEM_WIDTH_OFFSET_MOBILE } from './OnePick.styles';

export const onePickSectionSkeletonStyle = css`
  margin-top: 24px;
`;

export const onePickSectionItemSkeletonWrapperStyle = css`
  display: flex;
`;

export const onePickSectionItemSkeletonStyle = (theme: RIDITheme) => css`
  flex-shrink: 0;
  width: calc(100vw - ${ITEM_WIDTH_OFFSET_MOBILE}px);
  height: 406px;
  background-color: ${theme.colors.grey080};
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0px;
  }
`;
